import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import { MaterialSymbolsLanguage } from '@/assets/icons/comm/MaterialSymbolsLanguage';
import BaseInput from '@/components/base/baseInput';
import useUrlState from '@ahooksjs/use-url-state';
import { history, useModel } from '@umijs/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const [value, changeValue] = useState('');
  const { t } = useTranslation();
  const [urlState] = useUrlState<any>({});
  const { targetUrl } = urlState || {};
  const { areaCode, setCurrentAreaCode } = useModel('areaCode');
  // 数据 组装 将完全相等的数据放在前面 然后再将包含的放在后面
  const filterData =useMemo(()=>{
    const trimmedValue = value.trim();
    if (trimmedValue) {
      const exactMatches = (areaCode as any)?.filter((item: any) => {
        return (
          item.name.toLowerCase() === trimmedValue.toLowerCase() ||
          item.areaCode === trimmedValue
        );
      });
      const partialMatches = (areaCode as any)?.filter((item: any) => {
        return (
          item.name.toLowerCase().includes(trimmedValue.toLowerCase()) ||
          item.areaCode.includes(trimmedValue)
        ) &&!exactMatches.some((match: any) => match.id === item.id);
      });
      return [...exactMatches, ...partialMatches];
    } else {
      return areaCode;
    }
  },[value])

  return (
    <div className="sm:mt-20">
      <div className="bg-background  flex items-center mx-3" style={{position:'sticky',top:'0'}} >
        <BaseInput
          className="bg-backgroundAuxiliaryColor  overflow-hidden mt-2 mb-3 h-[40px]"
          value={value}
          startContent={
            <div>
              <BasilSearchSolid />
            </div>
          }
          onChange={(e) => changeValue(e.target.value)}
          placeholder={t('搜索')}
          classNames={{
            base: 'rounded-md overflow-hidden h-[40px]',
            inputWrapper:
              'h-full border-none rounded-[30px] rounded-md group-data-[focus=true]:border-backgroundAuxiliaryColor',
          }}
        ></BaseInput>
        <span
          className="ml-2 text-primary flex-shrink-0"
          onClick={() => {
            history.back();
          }}
        >
            {t('取消')}
          </span>
      </div>
      <div className="px-4">
        {
          // (areaCode as any)
          //   ?.filter((item: any) => {
          //     if (value.trim()) {
          //       return (
          //         item.name.toLowerCase().includes(value.toLowerCase()) ||
          //         item.areaCode.includes(value)
          //       );
          //     } else {
          //       return true;
          //     }
          //   })
          filterData?.map((item: any) => {
            return (
              <div
                key={item.id}
                className="flex justify-between mb-8 cursor-pointer"
                onClick={() => {
                  setCurrentAreaCode(item);
                  history.replace(targetUrl + location.search);
                }}
              >
                <div className="flex items-center">
                  {/* <MaterialSymbolsLanguage width="20px" height="20px" /> */}
                  <img
                    className="w-[20px] h-[20px] rounded-full object-cover"
                    src={item?.iconUrl}
                    alt="" />
                  <span className="ml-2 text-base">{item?.name}</span>
                </div>
                <span className="text-auxiliaryTextColor">
                  +{item?.areaCode}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};
